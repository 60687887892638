<template>
  <v-container :fluid="$vuetify.breakpoint.mdAndDown"
    class="px-10"
  >
    <v-row>
      <v-col>
        <h1 class="mt-5">アカウント管理</h1>
        <banner-hint>
          利用登録時に作成したID＆パスワードでログインするアカウントの他に、
          LINEアプリのQRコードリーダーでログイン可能なパスワード不要のアカウントを3つまで作成できます。<br />
        </banner-hint>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12"
        class="pt-5"
      >
        <!-- 要素追加ボタン -->
        <v-row class="mb-5">
          <v-col class="flex-grow-0 flex-shrink-1">
            <v-btn
              :disabled="credentials.length >= 4"
              depressed
              height="40"
              color="primary"
              @click="addLineLogin()"
            >LINEログインを追加</v-btn>
          </v-col>
          <v-col cols="12" sm="8">
            <icon-info icon="lightbulb-on" :square="true">
              LINEの提供するLINE認証機能を使ったログインはパスワードレスで簡単ながらも
              最新のセキュリティ技術で安全なアカウント管理を提供してくれます。
            </icon-info>
          </v-col>
        </v-row>

        <v-card
          class="px-10 pt-10 pb-7 mb-10"
          elevation="1"
          v-for="(credential, index) in credentials"
          :key="credential.id"
        >
          <v-form :ref="'form-' + credential.id">
            <v-row>
              <v-col cols="12">
                <!-- <v-select
                  v-if="credential.create"
                  v-model="credential.account_type"
                  label="アカウントタイプ"
                  :items="accountTypes"
                  item-value="type"
                  item-text="label"
                  item-color="primary"
                  :rules="[valiRules.required]"
                ></v-select> -->
                <v-text-field
                  :value="loginType(credential)"
                  label="アカウントタイプ"
                  outlined readonly
                  required
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model.trim="credential.login_id"
                  label="ログインID"
                  :hint="!isBasicLogin(credential) ? 'LINEから発行されるIDで変更はできません' : ''"
                  persistent-hint
                  :filled="!isBasicLogin(credential)"
                  :readonly="!isBasicLogin(credential)"
                  counter="50"
                  required
                  :rules="[valiRules.required, valiRules.max50]"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-if="credential.account_type === 'basic'"
                  v-model.trim="credential.password"
                  label="パスワード"
                  :filled="!isBasicLogin(credential)"
                  :readonly="!isBasicLogin(credential)"
                  :append-icon="credential.showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="credential.showPassword ? 'text' : 'password'"
                  autocomplete="off"
                  counter="15"
                  required
                  :rules="isBasicLogin(credential) ? [valiRules.required, valiRules.max15] : []"
                  @click:append="credential.showPassword = !credential.showPassword"
                ></v-text-field>
                <v-text-field
                  v-else
                  v-model.trim="credential.account_name"
                  label="アカウント名"
                  counter="30"
                  required
                  :rules="[valiRules.required, valiRules.max30]"
                ></v-text-field>
              </v-col>
            </v-row>

            <!-- <v-row>
              <v-col cols="12" sm="6">
                <v-select
                  v-model="credential.privilege_level"
                  :disabled="!isPrivileged || credential.account_type === 'basic'"
                  label="権限レベル"
                  :items="privilegeLevels"
                  item-value="level"
                  item-text="label"
                  item-color="primary"
                  :rules="[valiRules.required]"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6">
                <icon-info icon="information-variant">
                  Comming Soon!<br />
                  <span class="grey--text">権限のレベルによってアクセス可能な機能を制限することができます。</span>
                </icon-info>
              </v-col>
            </v-row> -->

            <!-- ボタン -->
            <v-row no-gutters justify="end" class="mt-5">
              <v-btn
                depressed
                :large="credential.create"
                :color="credential.create ? 'accent' : 'primary'"
                @click="update(credential)"
              >{{ btnRegisterLabel(credential) }}</v-btn>
              <v-btn
                class="ml-2"
                v-if="!isBasicLogin(credential)"
                text
                color="primary"
                @click="deleteLineCredential(index)"
              >削除</v-btn>
            </v-row>

          </v-form>
        </v-card>
      </v-col>
    </v-row>

    <!-- 確認モーダル -->
    <modal-confirm ref="modalConfirm">
      <div v-html="modalMessage"></div>
    </modal-confirm>

    <!-- ローダー -->
    <loader
      :loading="loading"
      :absolute="false"
    >
      {{ loadingMessage }}
    </loader>

    <!-- バリデーションアラート -->
    <v-snackbar
      v-model="snackbar.open"
      :timeout="3000"
      :color="snackbar.color"
      top
    >
      {{ snackbar.message }}
    </v-snackbar>
  </v-container>
</template>

<!-- ************************************* -->
<!-- ************* スクリプト ************** -->
<!-- ************************************* -->
<script>
import liff from '@line/liff'
import { LIFF_ID } from '@/literals.js'

import $literals from '@/literals.js'
import { ApiTool, CheckTokenError, ValidationRules } from '@/module.js'
import Loader from '@/components/_Loader.vue'
import ModalConfirm from '@/components/_ModalConfirm.vue'
import BannerHint from "@/components/_BannerHint.vue"
import IconInfo from "@/components/_IconInfo.vue"

export default {
  components: {
    'loader': Loader,
    'modal-confirm': ModalConfirm,
    'banner-hint': BannerHint,
    'icon-info': IconInfo,
  },

  props: {
    apiAdmin: {
      type: String,
      required: true
    },
    shopData: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      credentials: [],
      maxRecordId: 0,
      currentUserProfile: {},
      modalMessage: '',
      loading: false,
      loadingMessage: '',
      valiRules: ValidationRules,
      snackbar: {open: false, color: 'primary', message: ''},
      adminApi: new ApiTool(this.apiAdmin, this.shopData),
      accountTypes: [{type:'basic', label: 'パスワードログイン'}, {type:'line', label: 'LINEログイン'}],
      privilegeLevels: [{level:'normal', label: '一般管理者'}, {level:'privileged', label: '特権管理者'}],
    }
  },

  computed: {
    serverToken() {
      return sessionStorage.getItem('serverToken')
    },
    isBasicLogin() {
      return credential => credential.account_type === 'basic'
    },
    loginType() {
      return credential => this.isBasicLogin(credential) ? 'パスワードログイン' : 'LINEログイン'
    },
    isPrivileged() {
      return this.shopData.privilege_level !== 'normal'
    },
    btnRegisterLabel() {
      return credential => credential.create ? '登録してください' : '更新'
    }
  },

  created() {
    this.adminApi.setToken(this.serverToken)

    this.getData()
    .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
  },

  methods: {
    async getData() {
      this.loading = true
      this.loadingMessage = 'データ取得中・・・'

      await Promise.all([
        this.adminApi.getReqWithAuth('credential/').then( results => {
          if (!results || !results.length) return

          //id最大値の取得
          this.maxRecordId = Math.max(...results.map( row => parseInt(row.id) ))

          results.map( row => row.showPassword = false )
          this.credentials = results

          //LINEログイン追加のリダイレクトなら
          if (sessionStorage.getItem('redirectTo')) {
            // liff初期化
            return liff.init({ liffId: LIFF_ID }).then(() => {
              if (liff.isLoggedIn()) {
                liff.getProfile().then( prof => {

                  this.credentials.unshift({
                    id: ++this.maxRecordId,
                    login_id: prof.userId,
                    password: '',
                    account_type: 'line',
                    account_name: prof.displayName,
                    privilege_level: 'normal',
                    create: true
                  })

                  this.snackbar = {...{color:'success', message: '「登録」ボタンを押してアカウントを登録してください', open: true}}

                  sessionStorage.removeItem('redirectTo')
                  liff.logout()
                })
              } else {
                sessionStorage.removeItem('redirectTo')
              }
            })
          }
        }),
      ])

      this.loading = false
    },

    //
    // liffを使って
    //
    lineLogin() {
      return liff.init({ liffId: LIFF_ID }).then(() => {
        sessionStorage.setItem('redirectTo', this.$route.path)
        // sessionStorage.removeItem('shopDataArray')
        liff.logout()
        liff.login()
      })
    },

    addLineLogin() {
      if (this.shopData.system_plan_id < 2) {
        this.snackbar = {...{color:'warning', message: $literals.MESSAGE.availableForPaidPlanShort, open: true}}
        return
      } else {
        this.modalMessage = '<p>「追加」を押すとLINEが提供するLINE認証機能利用の認可確認画面が出ます。<br />'+
                            ' 追加したいLINEアカウントのQRコードリーダーで認可してください。</p>'

        const modalHanddown = {
          buttonLabel: '追加',
          yesCallback: this.lineLogin,
        }
        this.$refs.modalConfirm.open(modalHanddown)
      }
    },

    //**************************************************
    //**************************************************
    //                    APIコール
    //**************************************************
    //**************************************************
    //更新
    //**************************************************
    async update(credential) {
      if (!this.$refs['form-' + credential.id][0].validate()) {
        this.snackbar = {...{color:'warning', message: $literals.MESSAGE.validationFormInput, open: true}}
        return
      }

      if (credential.create) {
        //existチェック
        let exists
        const existQuery = {loginId: credential.login_id}
        await this.adminApi.getReqWithAuth('exists/credential/', existQuery).then( result => exists = result )

        if (exists) {
          this.snackbar = {...{color:'warning', message: $literals.MESSAGE.warningExistsSameLoginId, open: true}}
          return
        }
      }

      this.loading = true
      this.loadingMessage = 'データ更新中・・・'

      let id
      if (credential.create) id = 0
      else id = credential.id

      const apiPath = 'update/credential/' + id

      const updateData = {
        login_id: credential.login_id,
        password: credential.password,
        account_type: credential.account_type,
        account_name: credential.account_name,
        privilege_level: credential.privilege_level,
      }
      const payload = JSON.stringify(updateData)

      this.adminApi.apiReqWithData('PUT', apiPath, payload).then(() => {
        delete credential.create

        this.snackbar = {...{color:'success', message: $literals.MESSAGE.successUpdateSubmit, open: true}}
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
      .then(() => this.loading = false )
    },

    //
    // LINEアカウントの削除
    //
    deleteLineCredential(index) {
      //新規追加を削除
      if (this.credentials[index].create) {
        this.credentials.splice(index, 1)
        return
      }

      if (this.credentials.length === 1) {
        this.snackbar = {...{color:'success', message: $literals.MESSAGE.warningUnableToDeleteLastAccount, open: true}}
        return
      }

      this.loading = true
      this.loadingMessage = 'データ削除中・・・'

      const apiPath = 'delete/credential/' + this.credentials[index].id
      this.adminApi.apiReqWithData('DELETE', apiPath).then(() => {
        this.credentials.splice(index, 1)

        this.snackbar = {...{color:'info', message: $literals.MESSAGE.successDeleteSubmit, open: true}}
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
      .then(() => this.loading = false )
    }
  }
}
</script>

<style scoped>
</style>
